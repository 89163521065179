import axios from "axios";
import { useState } from "react";

import React from "react";

const initialState = {
  name: "",
  email: "",
  message: "",
  mobileNumber: "",
  businessName: "",
};
export const Contact = (props) => {
  const [{ name, email, mobileNumber, businessName, message }, setState] = useState(initialState);
  const [status, setStatus] = useState('');
  // const handleChange = (e) => {
  //   const { name, value } = e.target;

  //   setState((prevState) => ({ ...prevState, [name]: value }));
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the field is mobileNumber, enforce numeric input and a maximum of 10 digits
    if (name === "mobileNumber") {
      // Allow only digits and limit to 10 characters
      const formattedValue = value.replace(/[^0-9]/g, "").slice(0, 10); // Remove non-numeric characters and slice to 10 digits
      setState((prevState) => ({ ...prevState, [name]: formattedValue }));
    } else {
      // For other fields, just update the state as normal
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const clearState = () => setState({ ...initialState });

  function obj(name, email, mobileNumber, businessName, message) {
    const data = {
      name: name.trim(),
      email: email.trim(),
      mobileNumber: mobileNumber.trim(),
      businessName: businessName.trim(),
      message: message.trim()
    };
    return data;
  };

  async function sha256(inputString) {
    const encoder = new TextEncoder();
    const data = encoder.encode(inputString);

    // Compute the SHA-256 hash
    const hashBuffer = await crypto.subtle.digest('SHA-256', data);

    // Convert the hash buffer to a hexadecimal string
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');

    return hashHex;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(name, email, message);

    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }

    // Email validation regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    // Mobile number validation regex
    const mobileNumberRegex = /^[6-9]\d{9}$/;

    // Check if email is valid
    if (!emailRegex.test(email.trim())) {
      alert("Invalid email format");
      return;  // Stop submission if email is invalid
    }

    // Check if mobile number is valid
    if (!mobileNumberRegex.test(mobileNumber.trim())) {
      alert("Invalid mobile number format");
      return;  // Stop submission if mobile number is invalid
    }

    const postData = obj(name, email, mobileNumber, businessName, message);

    let Key = 'enquirysendkaraychaseltrprintanywhereinwebsitevarunchkarabaherunaccepthonarnai';
    let key_mob = Key.concat(mobileNumber);

    const hash = await sha256(key_mob);

    try {
      const response = await axios.post(`https://smitprint.printanywhere.in/web/enquiry/add`, postData, {
        headers: {
          'Content-Type': 'application/json',
          'WebTicket': hash
        },
      });
      // console.log(response);
      let status = response.status;
      setStatus(status);
    }
    catch (error) {
      // Handle error if the request fails
      // console.error('Error during POST request:', error);
      let status = error.status;
      setStatus(status);
    }
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          {/* <div style={{textAlign:'center'}}>
              <h2>Become our Partner</h2>
          </div> */}
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Become our Partner</h2>
                {status == '200' ? null : <p>
                  Please fill out the form below to send us an email and we will
                  get back to you as soon as possible.
                </p>}
              </div>
              {status == '200' ?
                (<h3><b>Thank you for joining us! <br />Our team will review your details and get in touch with you.</b></h3>)
                : status == '408' ?
                  (
                    <>
                      <h6 style={{ color: 'red', fontSize: '1.2rem' }}>* Mobile number already exists!</h6>
                      <form form name="sentMessage" validate onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                id="name"
                                name="name"
                                className="form-control"
                                placeholder="Name"
                                required
                                value={name}
                                onChange={handleChange}
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="email"
                                id="email"
                                name="email"
                                className="form-control"
                                placeholder="Email"
                                required
                                value={email}
                                onChange={handleChange}
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="text"
                                id="mobileNumber"
                                name="mobileNumber"
                                className="form-control"
                                placeholder="Mobile"
                                required
                                maxLength="10" // Limit input to 10 digits in the HTML
                                value={mobileNumber} // Make sure this is tied to state
                                onChange={handleChange}
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <input
                                type="business"
                                id="businessName"
                                name="businessName"
                                className="form-control"
                                placeholder="Business Name"
                                required
                                value={businessName}
                                onChange={handleChange}
                              />
                              <p className="help-block text-danger"></p>
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <textarea
                            name="message"
                            id="message"
                            className="form-control"
                            rows="4"
                            placeholder="Message"
                            // required
                            maxLength="500"
                            onChange={handleChange}
                            style={{ resize: 'none' }}
                            value={message}
                          ></textarea>
                          <p className="help-block text-danger"></p>
                        </div>
                        <div id="success"></div>
                        <button type="submit" className="btn btn-custom btn-lg">
                          Send Message
                        </button>
                      </form>
                    </>
                  ) :

                  (<form form name="sentMessage" validate onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-control"
                            placeholder="Name"
                            required
                            onChange={handleChange}
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            id="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            required
                            onChange={handleChange}
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            id="mobileNumber"
                            name="mobileNumber"
                            className="form-control"
                            placeholder="Mobile"
                            required
                            maxLength="10" // Limit input to 10 digits in the HTML
                            value={mobileNumber} // Make sure this is tied to state
                            onChange={handleChange}
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input
                            type="business"
                            id="businessName"
                            name="businessName"
                            className="form-control"
                            placeholder="Business Name"
                            required
                            onChange={handleChange}
                          />
                          <p className="help-block text-danger"></p>
                        </div>
                      </div>
                    </div>

                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="4"
                        placeholder="Message"
                        // required
                        maxLength="500"
                        onChange={handleChange}
                        style={{ resize: 'none' }}
                      ></textarea>
                      <p className="help-block text-danger"></p>
                    </div>
                    <div id="success"></div>
                    <button type="submit" className="btn btn-custom btn-lg">
                      Send Message
                    </button>
                  </form>)
              }
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {/* {props.data ? props.data.addres : ""} */}
                {props.data && props.data.address ? (
                  props.data.address.map((val, index) => (
                    <React.Fragment key={index}>
                      {val}
                      <br />
                    </React.Fragment>
                  ))
                ) : ""}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          {/* <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Issaaf Kattan React Land Page Template. Design by{" "}
            <a href="http://www.templatewire.com" rel="nofollow">
              TemplateWire
            </a>
          </p>
          <p>
            &copy; 2024 PrintAnywhere | Design by{" "}
            <a href="https://www.smitsolutions.co.in/" rel="nofollow">
              <img src={props.data ? props.data.logoImg : ''} alt="TemplateWire Icon" style={{ width: '50px', height: '20px', verticalAlign: 'middle' }} />
            </a>
          </p>
        </div>
      </div> */}
    </div >
  );
};
