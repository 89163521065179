import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Installation } from "./components/installation";
import { Route, Routes } from 'react-router-dom';
import HomePage from "./components/homepage";
import Guides from "./components/guides";
import PrivacyPolicy from "./components/PrivacyPolicy";
import DeleteAcount from "./components/deleteAcount";
import FileUpload from "./PrintComponents/FileUpload";
import FileViewer from "./PrintComponents/FileReview";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {

  // // Function to detect if DevTools are open
  // const detectDevToolsOpen = () => {
  //   const threshold = 160; // Height or width threshold for detecting DevTools
  //   return (
  //     window.outerHeight - window.innerHeight > threshold ||
  //     window.outerWidth - window.innerWidth > threshold
  //   );
  // };

  // // Function to handle resize event
  // const handleResize = () => {
  //   if (detectDevToolsOpen()) {
  //     window.location.href = 'https://www.google.com'; // Redirect to Google if DevTools are open
  //   }
  // };

  // // Set up event listeners when the component mounts
  // window.addEventListener('resize', handleResize);

  // // Initial check on component render
  // if (detectDevToolsOpen()) {
  //   window.location.href = 'https://www.google.com'; // Redirect if DevTools are open
  // }

  // // Cleanup function to remove the event listener
  // const cleanup = () => {
  //   window.removeEventListener('resize', handleResize);
  // };

  // React.useEffect(cleanup, []);

  // document.addEventListener("keydown", (e) => {
  //   if (e.key === "F12" || (e.ctrlKey && e.shiftKey && e.key === "I") || (e.ctrlKey && e.shiftKey && e.key === "C")) {
  //     e.preventDefault();
  //   }
  // });
  // document.addEventListener("contextmenu", (e) => e.preventDefault());


  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <>
      <Navigation data={landingPageData.Navigation} />
      {/* <Header data={landingPageData.Header} />
      <Features data={landingPageData.Features} />
      <About data={landingPageData.About} />

      <Contact data={landingPageData.Contact} />
      <Installation data={landingPageData.Install} /> */}

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/user-guide" element={<Guides data={landingPageData.UserGuide} />} />
        {/* <Route path="/features" element={<Features data={landingPageData.Features} />} />
          <Route path="/about" element={<About data={landingPageData.About} /> }/>
          <Route path="/contact" element={<Contact data={landingPageData.Contact} /> }/> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/delete-account" element={<DeleteAcount />} />
        {/* <Route path="/print-page" element={<FileUpload />} />
        <Route path="/file-viewer" element={<FileViewer data={landingPageData.printIcons}/>} /> */}
      </Routes>

    </>
  );
};

export default App;
