import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../PrintStyles/FileViewer.css';
import { MdOutlineCropPortrait, MdOutlineCropLandscape } from "react-icons/md";
import { pdfjs } from 'react-pdf';
import PdfComp from './PdfComp';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();


const FileViewer = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { files } = location.state || { files: [] };
  const [inputValue, setInputValue] = useState({}); // To store the input values dynamically
  const [colorsIcon, setColorsIcon] = useState(0);
  const [sideIcon, setSideIcon] = useState(0);
  const [orientIcon, setOrientIcon] = useState('portrait');
  const [advSetting, setAdvSetting] = useState({
    range: 'all',
    setting: false,
    size: 'A4',
    color: 'BW'
  });

  const [pageRange, setPageRange] = useState();
  const handleIncrement = (key) => {
    setInputValue((prevValues) => ({
      ...prevValues,
      [key]: prevValues[key] ? prevValues[key] + 1 : 1,
    }));
  };

  const handleDecrement = (key) => {
    setInputValue((prevValues) => ({
      ...prevValues,
      [key]: prevValues[key] > 0 ? prevValues[key] - 1 : 0,
    }));
  };

  const handleInputChange = (e, key) => {
    const value = e.target.value;
    if (!isNaN(value) && value >= 0) {
      setInputValue((prevValues) => ({
        ...prevValues,
        [key]: value,
      }));
    }
  };


  return (
    <div className="file-viewer-container">
      <div className="file-list-container">
        {/* <h2>Uploaded Files</h2>
        {files.length > 0 ? (
          <div>
            {files.map((file, index) => (
              <div key={index} className="file-item">
                <p>
                  <strong>File Name:</strong> {file.name}
                </p>
                {file.type.startsWith('image/') ? (
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    width="150"
                  />
                ) : (
                  <embed
                    src={URL.createObjectURL(file)}
                    type="application/pdf"
                    width="300"
                    height="500"
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          <p>No files uploaded!</p>
        )} */}
        <div className="pdf-scroll-container">
          <PdfComp files={files} colorMode={colorsIcon} orientations={orientIcon} twoSided={sideIcon} pageRange={pageRange} />
        </div>
      </div>

      {/* Right-side box */}
      <div className="right-side-box">
        <h3>Print Setting</h3>
        <hr />
        {props?.data?.length > 0 ?
          props?.data?.map((item, index) =>
          (
            <>
              <div className="icon-container">
                <div className="icon-item" key={index}>
                  <img src={item.icon} alt="Icon" className="icon" />
                  <p className='icon-name'>{item.title}</p>
                </div>

                {item.title === 'Copies' ? (
                  <div className="input-container">
                    <button
                      className="decrement-btn"
                      onClick={() => handleDecrement(index)}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={inputValue[index] || 0}
                      onChange={(e) => handleInputChange(e, index)}
                      className="input-box"
                      min="0"
                    />
                    <button
                      className="increment-btn"
                      onClick={() => handleIncrement(index)}
                    >
                      +
                    </button>
                  </div>
                ) : null}

                {/* {
                  item.title === 'Color' ?
                    item.Colors?.length > 0 &&
                    item.Colors.map((col, colIndex) => (
                      <div className="icon-item" key={colIndex} id='icon-border'>
                        <img src={col.icon} alt="Icon" className="icon" />
                        <p className='icon-name'>{col.title}</p>
                      </div>
                    ))
                    : null
                }

                {
                  item.title === 'Side' ?
                    item.Sides?.length > 0 &&
                    item.Sides.map((col, colIndex) => (
                      <div className="icon-item" key={colIndex} id='icon-border'>
                        <img src={col.icon} alt="Icon" className="icon" />
                        <p className='icon-name'>{col.title}</p>
                      </div>
                    ))
                    : null
                }


                {
                  item.title === 'Orientation' &&
                  <>
                    <div className="icon-item" id='icon-border'>
                      <MdOutlineCropPortrait className='icon' />
                      <p className='icon-name'>Portrait</p>
                    </div>
                    <div className="icon-item" id='icon-border'>
                      <MdOutlineCropLandscape className='icon' />
                      <p className='icon-name'>Landscape</p>
                    </div>
                  </>

                } */}


                {
                  item.title === 'Color' ?
                    item.Colors?.slice(0, 2).map((col, colIndex) => (
                      <div
                        key={colIndex}
                        id={colorsIcon === colIndex ? 'active-icon-border' : 'icon-border'}
                        className="icon-item"
                        onClick={() => setColorsIcon(colIndex)} active
                      >
                        <img
                          src={col.icon}
                          alt="Icon"
                          className={`icon ${colorsIcon === colIndex ? 'active-icon' : ''}`} 
                        />
                        <p className={`icon-name ${colorsIcon === colIndex ? 'active-icon-name' :''}`}>{col.title}</p>
                      </div>
                    ))
                    : null
                }

                {
                  item.title === 'Side' ?
                    item?.Sides?.slice(0, 2).map((col, colIndex) => (
                      <div
                        key={colIndex}
                        id={sideIcon === colIndex ? 'active-icon-border' : 'icon-border'} // Toggle id based on the state
                        className="icon-item"
                        onClick={() => setSideIcon(colIndex)} // Toggle active state on click
                      >
                        <img
                          src={col.icon}
                          alt="Icon"
                          className={`icon ${sideIcon === colIndex ? 'active-icon' : ''}`} // Apply active class to the icon
                        />
                        <p className={`icon-name ${sideIcon === colIndex ? 'active-icon-name' : ''}`}>{col.title}</p>
                      </div>
                    ))
                    : null
                }

                {
                  item.title === 'Orientation' &&
                  <>
                    <div
                      key="portrait"
                      id={orientIcon === 'portrait' ? 'active-icon-border' : 'icon-border'} // Toggle id based on the state
                      className="icon-item"
                      onClick={() => setOrientIcon('portrait')} // Set to portrait when clicked
                    >
                      <MdOutlineCropPortrait className={`icon ${orientIcon === 'portrait' ? 'active-icon' : ''}`} />
                      <p className={`icon-name' ${orientIcon === 'portrait' ? 'active-icon-name' : ''}`}>Portrait</p>
                    </div>
                    <div
                      key="landscape"
                      id={orientIcon === 'landscape' ? 'active-icon-border' : 'icon-border'} // Toggle id based on the state
                      className="icon-item"
                      onClick={() => setOrientIcon('landscape')} // Set to landscape when clicked
                    >
                      <MdOutlineCropLandscape className={`icon ${orientIcon === 'landscape' ? 'active-icon' : ''}`} />
                      <p className={`icon-name' ${orientIcon === 'landscape' ? 'active-icon-name' : ''}`}>Landscape</p>
                    </div>
                  </>
                }

              </div>
              <hr />
            </>
          )

          ) : null}

        {/* Additional Setting */}
        <div className='advance-setting' onClick={(e) => setAdvSetting({ ...advSetting, setting: !advSetting.setting })}>
          <h3>Advance Setting</h3>
        </div>
        <hr />
        {
          advSetting.setting === true &&
          <>
            <div className='sub-setting'>
              <h4>Color :</h4>
              <select name="print-range" id="range" value={colorsIcon} onChange={(e) => setColorsIcon(parseInt(e.target.value, 10))}>
                <option value="0">BW</option>
                <option value="1">Color</option>
                <option value="2">Both</option>
              </select>
            </div>
            <hr />
            {
              (colorsIcon === 0 || colorsIcon === 1) &&
              (
                <>
                  <div className='sub-setting'>
                    <h4>Print Range :</h4>
                    <select name="print-range" id="range" value={advSetting.range} onChange={(e) => setAdvSetting({ ...advSetting, range: e.target.value })}>
                      <option value="all">All</option>
                      <option value="custom">Custom</option>
                    </select>
                  </div>
                  {advSetting.range === 'custom' && (
                    <div className='print-range-div'>
                      <input type="text" placeholder='E.g. 1,3,1-5' value={pageRange} onChange={(e) => setPageRange(e.target.value)} />
                    </div>
                  )}
                  <hr />

                  <div className='sub-setting'>
                    <h4>Print Size :</h4>
                    <select name="print-range" id="range" value={advSetting.size} onChange={(e) => setAdvSetting({ ...advSetting, size: e.target.value })}>
                      <option value="A4">A4</option>
                      <option value="A3">A3</option>
                    </select>
                  </div>
                  <hr />
                </>
              )
            }

            {
              (colorsIcon === 2) &&
              (
                <>
                  <div className='sub-setting'>
                    <h4>BW Pages :</h4>
                    <input type="text" placeholder='E.g. 1,3 1-5' />
                  </div>

                  <hr />
                  <div className='sub-setting'>
                    <h4>Color Pages :</h4>
                    <input type="text" placeholder='E.g. 1,3 1-5' />
                  </div>
                  <hr />

                  <div className='sub-setting'>
                    <h4>Print Size :</h4>
                    <select name="print-range" id="range" value={advSetting.size} onChange={(e) => setAdvSetting({ ...advSetting, size: e.target.value })}>
                      <option value="A4">A4</option>
                      <option value="A3">A3</option>
                    </select>
                  </div>
                  <hr />
                </>
              )
            }
          </>
        }

        {/* Pay Now Button */}
        <button
          onClick={() => navigate('/pay')} // Replace with your desired route
          className="pay-now-button"
        >
          Pay Now
        </button>
      </div>
    </div>
  );
};

export default FileViewer;
